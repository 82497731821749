import { Badge } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { ISearchUnderlying } from "src/api/client/search-autocomplete.api";
import { Span } from "src/components/base";
import { Button } from "src/components/base/button";
import { WfLink } from "src/components/base/wf-link";
import { Highlight } from "src/components/common/highlight";
import { IconUnderlying } from "src/components/icons";
import { UnderlyingLogo } from "src/components/pages/underlying-overview/underlying-logo";
import { useLinkBuilder } from "src/hooks/use-link-builder";
import { EnumSecurityType } from "src/types/common-enum";
import { getTrackingUrlParam } from "src/utils/search-tracking-util";
import { IPlacement } from "../typeahead.types";

const NAV_UNDERLYING_SEARCH_ITEM_TEST_ID = "nav-underlying-search-item";

const UnderlyingBadge = (props: Pick<ISearchUnderlying, "securityType">) => {
    const { t } = useTranslation("common");

    switch (props.securityType) {
        case EnumSecurityType.Stock:
            return (
                <Badge pr={2} pl={2} variant="solid" colorScheme="green">
                    {t("stock")}
                </Badge>
            );
        case EnumSecurityType.ETF:
            return (
                <Badge pr={2} pl={2} variant="solid" colorScheme="lilac">
                    {t("etf")}
                </Badge>
            );
        case EnumSecurityType.Bond:
        case EnumSecurityType.Derivatives:
        case EnumSecurityType.Wikifolios:
        default:
            return null;
    }
};

interface IUnderlyingSearchItem {
    isSelected?: boolean;
    query?: string;
    placement: IPlacement;
    showBadge?: boolean;
}

export const UnderlyingSearchItem = ({
    resourceIdentifier,
    shortDescription,
    securityType,
    isSelected = false,
    query,
    placement,
    showBadge = true,
}: ISearchUnderlying & IUnderlyingSearchItem) => {
    const { buildUnderlyingLink } = useLinkBuilder();
    return (
        <WfLink
            px={[1, 3, 3, 3]}
            variant="unstyled"
            display="flex"
            alignItems="center"
            href={buildUnderlyingLink(resourceIdentifier) + getTrackingUrlParam({ placement, query })}
            h={7}
            backgroundColor={isSelected ? "gray.50" : undefined}
            _hover={{ bg: "gray.50" }}
            data-test-id={NAV_UNDERLYING_SEARCH_ITEM_TEST_ID}
        >
            <UnderlyingLogo isin={resourceIdentifier} size="xs" mr={2} />

            <Span flexGrow={1} maxW="100%" noOfLines={1} wordBreak="break-all">
                <Highlight source={shortDescription} target={query} />
            </Span>
            {showBadge && (
                <Span ml={1}>
                    <UnderlyingBadge securityType={securityType} />
                </Span>
            )}
        </WfLink>
    );
};

interface IUnderlyingSearchItemButton extends ISearchUnderlying, IUnderlyingSearchItem {
    onClick: () => void;
}

export const UnderlyingSearchItemButton = ({
    shortDescription,
    securityType,
    isSelected = false,
    query,
    onClick,
}: Omit<IUnderlyingSearchItemButton, "placement">) => {
    return (
        <Button
            pr={[2, 3]}
            pl={[2, 3]}
            pt={[4, 3]}
            pb={[4, 3]}
            w="100%"
            borderRadius="0"
            variant="unstyled"
            display="flex"
            backgroundColor={isSelected ? "gray.50" : undefined}
            _hover={{ bg: "gray.50" }}
            className="gtm-chart-analyze"
            onClick={onClick}
        >
            <IconUnderlying boxSize={2} color="gray.200" mr={1} />
            <Span textAlign="start" flexGrow={1} noOfLines={1} wordBreak="break-all">
                <Highlight source={shortDescription} target={query} />
            </Span>
            <Span ml={1}>
                <UnderlyingBadge securityType={securityType} />
            </Span>
        </Button>
    );
};
