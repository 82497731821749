import { PropsWithChildren } from "react";
import { Box } from "@chakra-ui/react";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { FooterMemo } from "src/components/layout/footer/footer";
import { IBasePageProps } from "src/types/layout";
import { Main } from "../base";
import { BannerSectionMemo } from "./banners/banner-section";
import { useNavHeight } from "./hooks/use-nav-height";
import Nav from "./nav";
import { OverlayContainer } from "./overlay-container";
import SideButtons from "./side-buttons/side-buttons";

export const NAV_HEIGHT = [7, 8, 10];

const DefaultLayout = (props: PropsWithChildren<IBasePageProps<unknown, unknown>>) => {
    const { navigation, user, footer, global, children } = props;

    const { NAV_HEIGHT_VARIATION: MAIN_PADDING_TOP } = useNavHeight();

    return (
        <Box display="flex" flexDir="column" minH="100vh">
            {navigation ? <Nav user={user} nav={navigation} height={NAV_HEIGHT} /> : null}
            <SkipNavContent />
            <Main pt={MAIN_PADDING_TOP} minH="70vh">
                {global && <BannerSectionMemo banners={global.banners} />}
                {children}
            </Main>
            {footer ? <FooterMemo {...footer} /> : null}
            <SideButtons showServiceButtons={Boolean(global?.serviceButtons)} serviceButtonsLabels={global?.serviceButtons} />
            <OverlayContainer />
        </Box>
    );
};

export default DefaultLayout;
