import { ILanguage } from "src/consts/locale";
import { EnumCountryKey } from "src/types/common-enum";

declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface String {
        format: (...args: unknown[]) => string;
    }
}

export const setupStringFormat = () => {
    if (!String.prototype.format) {
        // http://stackoverflow.com/questions/610406/javascript-equivalent-to-printf-string-format/4673436#4673436
        Object.defineProperty(String.prototype, "format", {
            enumerable: false,
            configurable: false,
            writable: false,
            value: function (...args: unknown[]) {
                return (this as string).replace(/{(\d+)}/g, function (match, val) {
                    return typeof args[val] !== "undefined" ? String(args[val]) : match;
                });
            },
        });
    }
};

export const addQuotationsToText = (text: string, language: ILanguage) => {
    // DEV-Note: DE language have different quotation mark
    return language === EnumCountryKey.DE ? `„${text}“` : `“${text}”`;
};
